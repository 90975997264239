import React from "react";
import theme from "theme";
import { Theme, Image, Section, Text, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Strona główna | Voxelystik Cleaning Co.
			</title>
			<meta name={"description"} content={"Nadajemy blask każdej przestrzeni - Voxelystik sprawia, że Twój świat lśni!"} />
			<meta property={"og:title"} content={"Strona główna | Voxelystik Cleaning Co."} />
			<meta property={"og:description"} content={"Nadajemy blask każdej przestrzeni - Voxelystik sprawia, że Twój świat lśni!"} />
			<meta property={"og:image"} content={"https://voxelystik.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voxelystik.com/img/190401.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voxelystik.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voxelystik.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voxelystik.com/img/190401.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://voxelystik.com/img/190401.png"} />
			<meta name={"msapplication-TileImage"} content={"https://voxelystik.com/img/190401.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0px 0 0px 0" quarkly-title="Images-19">
			<Override slot="SectionContent" width="100%" max-height="600px" />
			<Image src="https://voxelystik.com/img/1.jpg" object-fit="cover" object-position="top" />
		</Section>
		<Section padding="20px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Voxelystik Cleaning Co.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				W Voxelystik Cleaning Co. jesteśmy dumni z dostarczania najwyższej jakości usług sprzątania z odrobiną blasku. Nasz zespół oddanych profesjonalistów dokłada wszelkich starań, aby Twoja przestrzeń była nie tylko czysta, ale i lśniąco czysta. Od domów po b
			</Text>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			overflow-y="hidden"
			overflow-x="hidden"
			quarkly-title="Advantages/Features-12"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					lg-height="auto"
				>
					<Image
						height="589px"
						box-shadow="0 10px 20px -10px rgba(43, 45, 84, 0.15),0 0px 20px 0 rgba(43, 45, 84, 0.05)"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						src="https://voxelystik.com/img/2.jpg"
						object-fit="contain"
						lg-height="auto"
						lg-display="block"
						object-position="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 20px 0px"
				sm-margin="32px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="36px 0px 16px 0px"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Dlaczego warto wybrać Voxelystik Cleaning Co.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-max-width="720px"
				>
					- Dokładność i niezawodność: Dbamy o szczegóły i dokładamy wszelkich starań, aby żaden zakątek nie pozostał nietknięty.
					<br />
					<br />
					- Ekologiczne rozwiązania: Nasze metody czyszczenia są nie tylko skuteczne, ale także przyjazne dla środowiska.
					<br />
					<br />
					- Usługi dostosowane do indywidualnych potrzeb: Oferujemy szereg usług dostosowanych do konkretnych potrzeb, niezależnie od tego, czy chodzi o dogłębne czyszczenie, czy regularną konserwację.
					<br />
					<br />
					- Zaufani profesjonaliści: Nasz zespół jest starannie wyselekcjonowany i przeszkolony, aby zapewnić, że Twoja przestrzeń jest w dobrych rękach.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});